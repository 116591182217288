<template>
  <div class="addon-component" :class='{selected: selected}' ref="element" @click='$emit("click")'>
    <div class="icon addon-component__img">
      <div class='addon-component__icon'>
        <IconFree v-if='addon.price===0'/>
        <IconPrice v-if='addon.price > 0' :price='addon.price'/>
      </div>
      <img :src="image ? image : icon" :alt="name">
    </div>
    <span class="name"> {{ name }} </span>
  </div>
</template>

<script>

import newcompetition from '@/mixins/newcompetition'
import IconFree from '@/components/IconFree/IconFree'
import IconPrice from '@/components/IconPrice/IconPrice'

export default {
  name: 'Addon',
  components: {IconFree, IconPrice},
  mixins: [
    newcompetition
  ],
  props: {
    addon: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    id () {
      return this.addon.id
    },
    name () {
      return this.addon.name
    },
    icon () {
      return this.addon.icon
    },
    image () {
      return this.addon.image_url
    },
  },
  mounted () {
    if (this.selected) {
      this.scroll()
    }
  },
  methods: {
    scroll () {
      this.$refs.element.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }
}
</script>

<style scoped>
.addon-component__img {
  position: relative;
}
.addon-component__icon {
  position: absolute;
  bottom: 1px;
  left: 4px;
}
.addon-component__img img {
  width: 100%;
  object-fit: cover;
}
</style>
