<template>
  <subview class="newcompetition-appearence-addons">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.newcompetition.appearance.back') }}
          </div>
        </template>
        <template #last>
          <button @click="save">
            <Icon :size="24" type="save"/>

            {{ $t('pages.newcompetition.appearance.save') }}
          </button>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <h4> {{ title }} </h4>

      <AddonType v-for="addon in addons" :key="addon.id" :addon="addon" v-model="selected"/>
    </view-content>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import AddonType from '@/components/NewCompetition/Appearance/AddonType'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Addons',
  mixins: [
    newcompetition
  ],
  components: {
    Icon,
    AddonType
  },
  data () {
    return {
      selected: {}
    }
  },
  created () {
    this.selected = {...this.selectedAddonsIds}
  },
  computed: {
    title () {
      return this.selectedSport?.appearance.addons.title || this.$t('pages.newcompetition.appearance.addons.title')
    },
    addons () {
      return (this.selectedSport?.appearance.addons.variations || []).filter(({ values }) => values.length)
    }
  },
  methods: {
    save () {
      this.saveAddons(this.selected)
      this.$router.push({ name: 'newcompetition.appearance' })
    }
  }
}
</script>
