<template>
  <div class="addon-type-component" @click="togglePopup">
    <span class="name"> {{ name }} </span>
    <div class="value"> {{ valueAddon }} </div>

    <Icon :size="20" type="caret-right"/>

    <Popup :height="195" :open="popup" @close="togglePopup" containerClass="addons">
      <Addon v-for="addon in values" :key="addon.id" :addon="addon" :selected='selectedAddon(addon.id)' @click='toggleAddon(addon.id)'/>
    </Popup>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import Popup from '@/components/Popup'
import Addon from '@/components/NewCompetition/Appearance/Addon'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'AddonType',
  mixins: [
    newcompetition
  ],
  components: {
    Icon,
    Popup,
    Addon
  },
  props: {
    addon: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      popup: false,
      current: null
    }
  },
  computed: {
    id () {
      return this.addon.id
    },
    name () {
      return this.addon.name
    },
    valueAddon () {
      if (this.value[this.addon.id]===undefined) {
        return this.$t('pages.newcompetition.appearance.none')
      } else {
       return  this.values.find(item => item.id === this.value[this.addon.id]).name
      }
    },
    values () {
      return this.addon.values
    },
    selectedAddon() {
      return (selected) => {
        return this.value[this.addon.id]===selected
      }
    },
  },
  methods: {
    togglePopup () {
      this.popup = !this.popup
    },
    toggleAddon(addonType) {
      if (this.value[this.addon.id]===addonType) {
        const copy = {...this.value}
        delete copy[this.addon.id];
        this.$emit('input', copy)
      } else {
        this.$emit('input', {...this.value, [this.addon.id]: addonType})
      }
    }
  }
}
</script>
